import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PendingPage = () => {
    return (
        <Layout>
            <SEO title="Prime Ventures Alliance" keywords={[`investment`, `opportunity`]} />

            <div class="section2 container text-center">
                <div>
                    <h1>Thank you for your interest in PVA!</h1>
                    <p>Please check your email now (and spam folder) for a link to confirm your newsletter subscription.</p>
                </div>
            </div>
        </Layout>
    );
};

export default PendingPage;
